@font-face {
  font-family: 'Marck';
  src: url("./fonts/MarckScript-Regular.ttf")
}
@font-face {
  font-family: 'Josefin';
  src: url("./fonts/JosefinSans-VariableFont_wght.ttf")
}
@font-face {
  font-family: 'Philosopher';
  src: url("./fonts/Philosopher-Regular.ttf")
}
@font-face {
  font-family: 'Montserrat';
  src: url("./fonts/Montserrat-VariableFont_wght.ttf")
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat" !important;
}
