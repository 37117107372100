.activeLink{
    background-color: var(--chakra-colors-primary-500);
    border-radius: 20px;
    padding: 16px 8px;
    width: 100%;
    font-weight: bold;
    color: var(--chakra-colors-gray-100);
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: all 250ms linear;
}
.hoverLink{
    padding: 16px 8px;
    width: 100%;
    font-weight: bold;
    color: var(--chakra-colors-gray-100);
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: all 250ms linear;
}
.homeLink{
    padding: 16px 8px;
    width: 100%;
    font-weight: bold;
    color: var(--chakra-colors-gray-100);
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    transition: all 250ms linear;
  
}
.hoverLink:hover{
    background-color: var(--chakra-colors-primary-700);
    border-radius: 20px;
}
.gold {
    text-transform: uppercase;
    line-height:1;
    text-align: center;
    background: linear-gradient(90deg, rgba(186,148,62,1) 0%, rgba(236,172,32,1) 20%, rgba(186,148,62,1) 39%, rgba(249,244,180,1) 50%, rgba(186,148,62,1) 60%, rgba(236,172,32,1) 80%, rgba(186,148,62,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;	
    animation: shine 3s infinite;
    background-size: 200%;
    background-position: left;
  
  }
  @keyframes shine {
    to{background-position: right}
   
  }